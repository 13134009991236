import React, {useEffect, useRef, useState} from 'react'
import ConfigUtil from "../../../../util/ConfigUtil";
import FetchUtil from "../../../../util/FetchUtil";
import TournamentTable from "./TournamentTable";
import PropTypes from "prop-types";
import LoginDialog from "./LoginDialog";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import {OverlayPanel} from "primereact/overlaypanel";
import {InputText} from "primereact/inputtext";
import {PrimeIcons} from "primereact/api";
import {Menu} from "primereact/menu";
import {Button} from "primereact/button";
import {openEventOperator, openTournament} from "../../../../containers/views/public/PublicTournamentHelper";
import PublicTournamentBreadCumb from "./PublicTournamentBreadCrumb";

import DHDesktopDiv from "../../../general/DHDesktopDiv";
import DHMobileDiv from "../../../general/DHMobileDiv";
import PublicEventoperatorSearchTab from "../eventoperator/PublicEventoperatorSearchTab";
import LastEventComp from "../../../LastEventComp";
import MobileMenuArea from "../../../general/MobileMenuArea";


function PublicTournaments({isLoggedIn}) {

    const classes = {};

    const filterTournamentsOP = useRef(null);

    const [filterTournamentText, setFilterTournamentText] = React.useState("");

    const [showLoginDialog, setShowLoginDialog] = React.useState(false);
    const [tabValue, setTabValue] = React.useState("turniere");
    let [tournaments, setTournaments] = useState([]);
    let [tournamentsLive, setTournamentsLive] = useState([]);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const menu = useRef(null);

    useEffect(() => {
        searchForTournaments();
    }, []);

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const onResize = (event) => {
        setWindowDimensions(getWindowDimensions);
    }

    const filterTournaments = () => {
        if (filterTournamentText === "") {
            return tournaments;
        }
        let returnList = [];
        for (const tournament of tournaments) {
            let leagueOperatorBezeichnungIncludes = tournament.leagueOperatorBezeichnung !== null && tournament.leagueOperatorBezeichnung.toUpperCase().includes(filterTournamentText.toUpperCase());
            let eventOperatorBezeichnungIncludes = tournament.eventOperatorBezeichnung !== null && tournament.eventOperatorBezeichnung.toUpperCase().includes(filterTournamentText.toUpperCase());
            let bezeichnungIncludes = tournament.bezeichnung.toUpperCase().includes(filterTournamentText.toUpperCase());
            if (leagueOperatorBezeichnungIncludes || eventOperatorBezeichnungIncludes || bezeichnungIncludes) {
                returnList.push(tournament);
            }
        }
        return returnList;
    }

    const searchForTournaments = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.public + "/tournaments",
            json => {
                setTournaments(json.tournaments);
                setTournamentsLive(json.liveTournaments);
            },
            responseNotOk => {
                console.log(responseNotOk.message);
            },
            error => {
                console.log(error.message);
            });
    }

    const erzeugeTab = (value, key, icon) => {
        return <div key={"key_tab_" + key} style={{color: "white", fontSize: 20, width:180, marginLeft:5, cursor:"pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>
            {icon !== undefined &&
                <i className={icon}
                   style={{fontSize: 20, marginLeft: 5, marginRight: 5, color: "white", cursor: "pointer"}}/>
            }
            {value.toUpperCase()}
        </div>
    }

    const getVeranstalterTab = () => {
        return <div style={{
            marginTop: 10,
            padding: 20,
            color:"white",
        }}>
            <PublicEventoperatorSearchTab />
        </div>
    }

    const getLiveTab = () => {
        return <div style={{
            marginTop: 10,
            padding: 0,
            height: windowDimensions.height - 240,
            maxHeight: windowDimensions.height - 240
        }}>
            <TournamentTable tournaments={tournamentsLive} showEventOperator={true}
                             onOpenEventOperator={(tournament) => openEventOperator(tournament)}
                             onOpen={(tournament) => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_TOURNAMENT_LIVE_VIEW.route + "?tournamentid=" + tournament.id, "_self")}/>
        </div>
    }

    const getTournamentsTab = () => {
        return <>
            <div style={{display: "flex", marginLeft: 15, marginBottom: 10}}>
                <OverlayPanel ref={filterTournamentsOP}
                              showCloseIcon={false} dismissable={true}
                              style={{height: 150, width: 200}}>
                    <div style={{marginTop: 20}}>
                        <span className="p-float-label">
                            <InputText id="filterTournamentText" value={filterTournamentText}
                                       onChange={(e) => setFilterTournamentText(e.target.value)}/>
                            <label htmlFor="filterTournamentText">Filter</label>
                        </span>
                    </div>
                    <div style={{marginTop: 20}}>
                        <Button className="p-button-text" label="Filter löschen" onClick={() => setFilterTournamentText("")}/>
                    </div>

                </OverlayPanel>
                <div style={{color: "white", marginTop: 10, marginRight: 10, fontSize: 20}}>Anzahl
                    Events: {tournaments.length}</div>
                <i className="pi pi-filter" style={{color: "white", width: 40, height: 30, fontSize: 30}}
                   onClick={(e) => filterTournamentsOP.current.toggle(e)}/>
                {filterTournamentText !== "" &&
                    <div style={{
                        color: "white",
                        marginRight: 10,
                        fontSize: 20
                    }}>Gefiltert: {filterTournaments().length}</div>
                }
            </div>
            <DHDesktopDiv comp={
                <div style={{
                    padding: 0,
                    height: windowDimensions.height - 200,
                    maxHeight: windowDimensions.height - 200
                }}>
                    <TournamentTable tournaments={filterTournaments()} showEventOperator={true}
                                     onOpenEventOperator={(tournament) => openEventOperator(tournament)}
                                     onOpen={(tournament) => openTournament(tournament, isLoggedIn, () => setShowLoginDialog(true))}/>
                </div>
            }/>
            <DHMobileDiv comp={
                <div style={{padding: 0, height: windowDimensions.height - 240, maxHeight: windowDimensions.height - 240}}>
                    <TournamentTable tournaments={filterTournaments()} showEventOperator={true}
                                     onOpenEventOperator={(tournament) => openEventOperator(tournament)}
                                     onOpen={(tournament) => openTournament(tournament, isLoggedIn, () => setShowLoginDialog(true))}/>
                </div>
            }/>
        </>
    }

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Events" ,"turniere"));
        returnValue.push(erzeugeTab("Livescoring" ,"live"));
        returnValue.push(erzeugeTab("Veranstalter" ,"veranstalter"));
        return returnValue;
    }

    const getTabpanel = () => {
        if (tabValue === "turniere") {
            return getTournamentsTab();
        } else if (tabValue === "live") {
            return getLiveTab();
        } else if (tabValue === "veranstalter") {
            return getVeranstalterTab();
        }
    }

    const getMenuItems = () => {
        let returnValue = [];
        returnValue.push({label: 'Events', icon: PrimeIcons.CHART_LINE, command: () => setTabValue("turniere"), style:{borderLeft: tabValue === "turniere" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Livescoring', icon: PrimeIcons.EYE, command: () => setTabValue("live"), style:{borderLeft: tabValue === "live" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Veranstalter', icon: PrimeIcons.BOX, command: () => setTabValue("veranstalter"), style:{borderLeft: tabValue === "veranstalter" ? "10px solid #ac1c27" : "none"}});
        return returnValue;
    }

    const getDesktopHeader = () => {
        return <>
            <div style={{width: "100%", display: "block"}}>
                <PublicTournamentBreadCumb isMobil={false}/>
                <LastEventComp currentId={""}/>
                <div style={{marginBottom: 20}}>
                    <div style={{display: "flex"}}>
                        {getTabs()}
                    </div>
                </div>
            </div>
        </>
    }

    const getMobileHeader = () => {
        return <>
            <div>
                <PublicTournamentBreadCumb isMobil={true}/>
            </div>
            <div style={{display: "flex"}}>
                <MobileMenuArea onClick={(e) => menu.current.toggle(e)}/>
                <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENTS.route} style={{textDecoration: "none"}}>
                    <img alt="Logo" src={"images/darthelfer_quer_weiss.png"} style={{height: 50, margin: 10}}/>
                </NavLink>
                <Menu model={getMenuItems()} popup ref={menu} style={{fontSize: 30, width: 300}}/>
            </div>
            <LastEventComp currentId={""}/>
        </>
    }

    return <div style={{backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
        <DHDesktopDiv comp={getDesktopHeader()}/>
        <DHMobileDiv comp={getMobileHeader()}/>
        {getTabpanel()}
        {showLoginDialog &&
            <LoginDialog onClose={() => setShowLoginDialog(false)}/>
        }
    </div>
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

PublicTournaments.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired
};

export default PublicTournaments;
