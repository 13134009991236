import React, {useEffect, useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {ToggleButton} from "primereact/togglebutton";
import {InputNumber} from "primereact/inputnumber";
import RanglisteMarkerComponent from "../liga/RanglisteMarkerComponent";
import {InputTextarea} from "primereact/inputtextarea";
import {TabPanel, TabView} from "primereact/tabview";
import {TOURNAMENTMODUS} from "../../../constants/tournamentModus";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import TurnierSponsorenTabPanel from "./tabs/TurnierSponsorenTabPanel";
import TurnierAdminTabPanel from "./tabs/TurnierAdminTabPanel";
import LigaAktionenComponent from "./tabs/LigaAktionenComponent";
import TournamentX01Area from "./TournamentX01Area";
import TournamentCricketArea from "./TournamentCricketArea";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import TurnierQRCode from "../liga/TurnierQRCode";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {SelectButton} from "primereact/selectbutton";
import InfoDialogEventNichtVeroeffentlichen from "../../general/InfoDialogEventNichtVeroeffentlichen";
import DHDialog from "../../general/DHDialog";
import DHDatePicker from "../../general/DHDatePicker";
import DHDateTimePicker from "../../general/DHDateTimePicker";
import DHDefaultDrawer from "../../general/DHDefaultDrawer";

function LigaVerwaltenDialog({ligaId, propEventOperatorId, callBackOnClose, callBackRefresh}) {

    const darttypes = [
        {name: 'Steeldarts', code: 'STEELDARTS'},
        {name: 'E-Dart', code: 'EDART'},
    ];

    let [showPublishTournamentNotice, setShowPublishTournamentNotice] = useState(false);
    let [showScoringNotice, setShowScoringNotice] = useState(false);
    let [showScoringTypeNotice, setShowScoringTypeNotice] = useState(false);
    let [showAnmeldungPublicNotice, setShowAnmeldungPublicNotice] = useState(false);
    let [showAnmeldungUnbestaetigtNotice, setShowAnmeldungUnbestaetigtNotice] = useState(false);
    let [showBezahlungNotice, setShowBezahlungNotice] = useState(false);
    let [showBezahlungBetragNotice, setShowBezahlungBetragNotice] = useState(false);
    let [showMailNotice, setShowMailNotice] = useState(false);
    let [showTeilnahmePerMailNotice, setShowTeilnahmePerMailNotice] = useState(false);
    let [showKennwortNotice, setShowKennwortNotice] = useState(false);
    let [showMaxAverageNotice, setShowMaxAverageNotice] = useState(false);
    let [showTeilnahmebedingungenNotice, setShowTeilnahmebedingungenNotice] = useState(false);
    let [showUrkundendruckNotice, setShowUrkundendruckNotice] = useState(false);
    let [showRanglisteNotice, setShowRanglisteNotice] = useState(false);
    let [showOnlineNotice, setShowOnlineNotice] = useState(false);

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [liga, setLiga] = useState(undefined);

    let [eventOperatorId, setEventOperatorId] = useState(propEventOperatorId);

    let [admins, setAdmins] = useState([]);
    let [sponsoren, setSponsoren] = useState([]);

    let [gameType, setGameType] = useState(undefined);
    let [id, setId] = useState(undefined);
    let [turnierstatus, setTurnierstatus] = useState(undefined);
    let [bezeichnung, setBezeichnung] = useState("");
    let [kennwort, setKennwort] = useState("");
    let [maximaleAnzahlTeilnehmer, setMaximaleAnzahlTeilnehmer] = useState(undefined);
    let [beginn, setBeginn] = useState(undefined);
    let [anmeldeende, setAnmeldeende] = useState(undefined);
    let [anzeigenAb, setAnzeigenAb] = useState(undefined);
    let [teilnahmebedingungen, setTeilnahmebedingungen] = useState(false);
    let [hinUndRueckspiel, setHinUndRueckspiel] = useState(false);
    let [doppelturnier, setDoppelturnier] = useState(false);
    let [averageObergrenze, setAverageObergrenze] = useState(undefined);
    let [urkundendruck, setUrkundendruck] = useState(false);
    let [rangliste, setRangliste] = useState(false);
    let [ranglistemarkers, setRanglistemarkers] = useState([]);
    let [beschreibung, setBeschreibung] = useState("");
    let [sets, setSets] = useState(undefined);
    // sobald der EventOperator nicht gesetzt ist, handelt es sich um keinen Veranstalter somit um einen Onlineveranstalter. Dieser kann nur Online spielen.
    let [online, setOnline] = useState(eventOperatorId === null || eventOperatorId === undefined);
    let [bezahlung, setBezahlung] = useState(false);
    let [bezahlungbetrag, setBezahlungbetrag] = useState("");
    let [mailtext, setMailtext] = useState("");
    let [homepage, setHomepage] = useState("");
    let [socialmedia, setSocialmedia] = useState("");
    let [austragungsortBezeichnung, setAustragungsortBezeichnung] = useState("");
    let [austragungsortStrasse, setAustragungsortStrasse] = useState("");
    let [austragungsortPLZ, setAustragungsortPLZ] = useState("");
    let [austragungsortOrt, setAustragungsortOrt] = useState("");
    let [austragungsortEmail, setAustragungsortEmail] = useState("");
    let [austragungsortTelefon, setAustragungsortTelefon] = useState("");
    let [publishTournament, setPublishTournament] = useState(true);
    let [scoringWithDarthelfer, setScoringWithDarthelfer] = useState(true);
    let [dartType, setDartType] = useState(darttypes[0]);
    let [registrationAllowed, setRegistrationAllowed] = useState(true);
    let [deleteUnconfirmedRegistrations, setDeleteUnconfirmedRegistrations] = useState(true);
    let [participantsMailConfirmation, setParticipantsMailConfirmation] = useState(true);

    let [bestOfLegs, setBestOfLegs] = useState(undefined);
    let [firstToLegs, setFirstToLegs] = useState(undefined);
    let [modusId, setModusId] = useState(undefined);
    let [startpunkte, setStartpunkte] = useState(undefined);
    let [zufallszahlen, setZufallszahlen] = useState(null);
    let [zufallszahlenjedesleg, setZufallszahlenjedesleg] = useState(null);
    let [cutthroat, setCutthroat] = useState(null);

    let [showPublishTournamentInfo, setShowPublishTournamentInfo] = useState(false);

    useEffect(() => {
        if(!publishTournament) {
            setShowPublishTournamentInfo(true);
        }
    }, [publishTournament]);

    function istTurnierGestartet() {
        return turnierstatus === "gestartet";
    }

    function istTurnierAbgeschlossen() {
        return turnierstatus === "abgeschlossen";
    }

    function ladeLiga(id) {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/ladeliga/" + id,
            json => {
                let tournament = json.tournamentDto;
                setLiga(tournament);
                setId(tournament.id);
                setEventOperatorId(tournament.eventOperatorId);
                setBezeichnung(tournament.bezeichnung);
                if (tournament.beginn !== undefined && tournament.beginn !== null) {
                    setBeginn(tournament.beginn);
                }
                setGameType(tournament.gameType);
                setOnline(tournament.online);
                setTurnierstatus(tournament.tournamentstatus);
                let modusEntity = tournament.tournamentModusEntities[0];
                setSets(modusEntity.sets);
                setFirstToLegs(modusEntity.firstToLegs);
                setBestOfLegs(modusEntity.bestOfLegs);
                if (tournament.gameType === "x01") {
                    setModusId(modusEntity.modusid);
                    setStartpunkte(modusEntity.startpunkte);
                } else if (tournament.gameType === "cricket") {
                    setZufallszahlen(modusEntity.zufallszahlen);
                    setZufallszahlenjedesleg(modusEntity.zufallszahlenjedesleg);
                    setCutthroat(modusEntity.cutthroat);
                }
                setBeschreibung(tournament.beschreibung);
                setKennwort(tournament.kennwort);
                setMaximaleAnzahlTeilnehmer(tournament.maxAnzahlTeilnehmer);
                setAverageObergrenze(tournament.averageObergrenze);
                if (tournament.anmeldeende !== undefined && tournament.anmeldeende !== null) {
                    setAnmeldeende(tournament.anmeldeende);
                }
                if (tournament.anzeigenAb !== undefined && tournament.anzeigenAb !== null) {
                    setAnzeigenAb(tournament.anzeigenAb);
                }
                setTeilnahmebedingungen(tournament.teilnahmebedingungen);
                setHinUndRueckspiel(tournament.hinUndRueckspiel);
                setDoppelturnier(tournament.doppelturnier);
                setUrkundendruck(tournament.urkunden);
                setRangliste(tournament.rangliste);
                setRanglistemarkers(tournament.ranglistemarkers);
                let adminsNachDemLaden = [];
                for (const tmpAdmin of tournament.administratoren) {
                    adminsNachDemLaden.push(tmpAdmin.userId);
                }
                setAdmins(adminsNachDemLaden);
                let sponsorenNachDemLaden = [];
                for( const tmpSponsor of tournament.tournamentSponsorenEntities) {
                    sponsorenNachDemLaden.push(tmpSponsor.sponsorEntity.id)
                }
                setSponsoren(sponsorenNachDemLaden);

                setBezahlung(tournament.bezahlung);
                setBezahlungbetrag(tournament.bezahlungBetrag);
                setMailtext(tournament.mailtext);
                setAustragungsortBezeichnung(tournament.austragungsortBezeichnung);
                setAustragungsortStrasse(tournament.austragungsortStrasse);
                setAustragungsortPLZ(tournament.austragungsortPLZ);
                setAustragungsortOrt(tournament.austragungsortOrt);
                setAustragungsortTelefon(tournament.telefon);
                setAustragungsortEmail(tournament.email);
                setSocialmedia(tournament.socialmedia);
                setHomepage(tournament.homepage);
                setParticipantsMailConfirmation(tournament.participantsMailConfirmation);
                setRegistrationAllowed(tournament.registrationAllowed);
                setDeleteUnconfirmedRegistrations(tournament.deleteUnconfirmedRegistrations);
                setPublishTournament(tournament.publishTournament);
                setScoringWithDarthelfer(tournament.scoringWithDarthelfer);
                if( tournament.dartType === null || tournament.dartType === undefined) {
                    setDartType(darttypes[0]);
                } else {
                    let tmpDartType = darttypes[0];
                    for (const tmp of darttypes) {
                        if( tmp.code === tournament.dartType) {
                            tmpDartType = tmp;
                        }
                    }
                    setDartType(tmpDartType);
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
        setShowPublishTournamentInfo(false);
    }

    useEffect(() => {
        if (ligaId === null || ligaId === undefined) {
            return;
        }
        ladeLiga(ligaId);
    }, []);

    function save() {
        setErrorMessage(undefined);
        let modusArray = [];
        if (gameType === "x01") {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.LIGA.id,
                gameType: gameType,
                modusid: modusId,
                sets: sets,
                firstToLegs: firstToLegs,
                bestOfLegs: bestOfLegs,
                startpunkte: startpunkte
            });
        } else if (gameType === "cricket") {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.LIGA.id,
                gameType: gameType,
                sets: sets,
                firstToLegs: firstToLegs,
                bestOfLegs: bestOfLegs,
                zufallszahlen: zufallszahlen,
                zufallszahlenjedesleg: zufallszahlenjedesleg,
                cutthroat: cutthroat
            });
        }

        let adminsZumSpeichern = [];
        for (const admin of admins) {
            adminsZumSpeichern.push({userId: admin});
        }

        let myPublishTournament;
        if( eventOperatorId === null || eventOperatorId === undefined) {
            // kein eventOperator, daher immer veröffentlichen
            myPublishTournament = true;
        } else {
            myPublishTournament =  publishTournament;
        }

        let data = {
            id: id, eventOperatorId: eventOperatorId, gameType: gameType,
            participantsMailConfirmation: participantsMailConfirmation,
            bezahlung: bezahlung,
            bezahlungBetrag: bezahlungbetrag,
            socialmedia: socialmedia,
            homepage: homepage,
            austragungsortBezeichnung: austragungsortBezeichnung,
            austragungsortStrasse: austragungsortStrasse,
            austragungsortPLZ: austragungsortPLZ,
            austragungsortOrt: austragungsortOrt,
            email: austragungsortEmail,
            telefon: austragungsortTelefon,
            mailtext: mailtext,
            registrationAllowed: registrationAllowed,
            deleteUnconfirmedRegistrations: deleteUnconfirmedRegistrations,
            publishTournament: myPublishTournament,
            scoringWithDarthelfer: scoringWithDarthelfer,
            dartType: dartType.code,
            online: online,
            bezeichnung: bezeichnung, beschreibung: beschreibung,
            beginn: beginn, anmeldeende: anmeldeende, anzeigenAb: anzeigenAb,
            hinUndRueckspiel: hinUndRueckspiel,
            kennwort: kennwort,
            turniersystem: TOURNAMENTSYSTEMS.LIGA.id,
            maximaleAnzahlTeilnehmer: maximaleAnzahlTeilnehmer,
            doppelturnier: doppelturnier, urkunden: urkundendruck,
            rangliste: rangliste, ranglistemarkers: ranglistemarkers,
            teilnahmebedingungen: teilnahmebedingungen, averageObergrenze: averageObergrenze,
            administratoren: adminsZumSpeichern,
            sponsorenZumSpeichern: sponsoren,
            tournamentModusEntities: modusArray,
        };
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/save",
            data,
            json => {
                ladeLiga(json.id);
                setInfoMessage("Turnier wurde erfolgreich gespeichert.");
                callBackRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );
    }

    function eventOperatorDatenVorbelegen() {
        setErrorMessage(undefined);

        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/byId/" + eventOperatorId,
            json => {
                setHomepage(json.homepage);
                setSocialmedia(json.socialmedia);
                setAustragungsortBezeichnung(json.austragungsortBezeichnung);
                setAustragungsortStrasse(json.austragungsortStrasse);
                setAustragungsortPLZ(json.austragungsortPLZ);
                setAustragungsortOrt(json.austragungsortOrt);
                setAustragungsortEmail(json.email);
                setAustragungsortTelefon(json.telefon);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Veranstalterdaten: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Veranstalterdaten: " + error.message);
            }
        );
    }



    function showSaveButton() {
        if (!bezeichnung || !beginn) {
            return false;
        }
        if( eventOperatorId !== null && eventOperatorId !== undefined && (publishTournament === undefined || publishTournament === null)) {
            return false;
        }
        if (gameType === "x01") {
            return sets && (firstToLegs || bestOfLegs) && modusId && startpunkte;
        } else if (gameType === "cricket") {
            return sets && (firstToLegs || bestOfLegs) && zufallszahlen !== null && cutthroat !== null;
        } else {
            return false;
        }
    }

    const dialogFooter = (
        <div>
            <Button label="Speichern" icon="pi pi-save" style={{marginRight: 5}} disabled={!showSaveButton()} onClick={() => save()}/>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => callBackOnClose()}
                    className="p-button-secondary"/>
        </div>
    );

    return <DHDialog onClose={callBackOnClose} title="Liga anlegen/bearbeiten" buttons={dialogFooter} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div className="grid">
            <div className="col-fixed" style={{width: 120}}>Bezeichnung: *</div>
            <div className="col"><InputText  disabled={istTurnierAbgeschlossen()} style={{width: "350px"}} value={bezeichnung}
                                            onChange={(e) => setBezeichnung(e.target.value)}/></div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width: 120}}>Startzeitpunkt: *</div>
            <div className="col">
                <DHDatePicker disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} value={beginn} changeValue={setBeginn}/>
            </div>
        </div>
        <TabView style={{width: "100%", paddingTop:10, paddingLeft:10, paddingRight:10, border: "none"}}>
            <TabPanel header="Modus">
                <div className="grid" style={{textAlign: "left"}}>
                    <div className="flex align-items-center justify-content-center h-4rem"
                         style={{marginLeft: 5, marginRight: 5}}>
                        <ToggleButton style={{width: 200}} checked={gameType === 'x01'} offLabel="X01" onLabel="X01"
                                      onChange={(e) => setGameType('x01')} className={ istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}/>
                        {(eventOperatorId === null || eventOperatorId === undefined) &&
                            <ToggleButton style={{width: 200}} checked={gameType === 'cricket'} offLabel="Cricket"
                                          onLabel="Cricket" onChange={(e) => setGameType('cricket')} className={ istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}/>
                        }
                    </div>
                </div>
                {gameType === "x01" &&
                    <TournamentX01Area showSets={true} sets={sets} showBestOfLegs={true} firstToLegs={firstToLegs} bestOfLegs={bestOfLegs} modusId={modusId} startpunkte={startpunkte}
                                       onSetsChanged={(sets) => setSets(sets)}
                                       onFirstToLegsChanged={(firstToLegs) => setFirstToLegs(firstToLegs)}
                                       onBestOfLegsChanged={(bestOfLegs) => setBestOfLegs(bestOfLegs)} onModusIdChanged={(modusId) => setModusId(modusId)}
                                       onStartpunkteChanged={(startpunkte) => setStartpunkte(startpunkte)}/>
                }
                {gameType === "cricket" &&
                    <TournamentCricketArea sets={sets} bestOfLegs={bestOfLegs} showSets={true} showBestOfLegs={true} showPflichtfelder={true}
                                           firstToLegs={firstToLegs}
                                           zufallszahlen={zufallszahlen} zufallszahlenjedesleg={zufallszahlenjedesleg} cutthroat={cutthroat}
                                           onSetsChanged={(sets) => setSets(sets)}
                                           onFirstToLegsChanged={(firstToLegs) => setFirstToLegs(firstToLegs)}
                                           onBestOfLegsChanged={(bestOfLegs) => setBestOfLegs(bestOfLegs)}
                                           onZufallszahlenChanged={(value) => setZufallszahlen(value)}
                                           onZufallszahlenjedeslegChanged={(value) => setZufallszahlenjedesleg(value)}
                                           onCutthroatChanged={(value) => setCutthroat(value)}/>
                }
            </TabPanel>
            <TabPanel header="Optionale Eingaben">
                <div className="grid">
                    <div className="col-fixed" style={{width: 200}}>Beschreibung:</div>
                </div>
                <div className="grid">
                    <div className="col" style={{width: "100%"}}>
                        <InputTextarea rows={5} cols={50} maxLength={10000} value={beschreibung}
                                       onChange={(e) => setBeschreibung(e.target.value)}/>
                    </div>
                </div>
                <hr/>
                <div className="grid">
                    <div className={"col-12 md:col-12 lg:col-12"} style={{padding: 10}}>
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowKennwortNotice(false)} show={showKennwortNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>Die Eingabe des Kennworts ist optional. Sobald ein
                                        Kennwort angegeben wird, muss der Benutzer bei
                                        der Teilnahme ein Kennwort eingeben.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 200}}>Kennwort für Ligateilnahme:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={(e) => setShowKennwortNotice(!showKennwortNotice)}/>
                            </div>
                            <div className="col">
                                <InputText disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} value={kennwort} style={{width: 200}}
                                           onChange={(e) => setKennwort(e.target.value)}/>
                            </div>
                        </div>
                        <hr/>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 200}}>Maximale Teilnehmerzahl:</div>
                            <div className="col">
                                    <InputNumber
                                        value={maximaleAnzahlTeilnehmer}
                                        disabled={istTurnierGestartet() || istTurnierAbgeschlossen()}
                                        onValueChange={(e) => setMaximaleAnzahlTeilnehmer(e.value)} min={1}/>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 200}}>Anmeldeende:</div>
                            <div className="col-fixed" style={{width: 300}}>
                                <DHDateTimePicker disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} value={anmeldeende} changeValue={setAnmeldeende}/>
                            </div>
                        </div>
                        {(eventOperatorId === null || eventOperatorId === undefined) &&
                            <div className="grid">
                                <div className="col-fixed" style={{width: 200}}>Anzeigen ab:</div>
                                <div className="col">
                                    <DHDateTimePicker disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} value={anzeigenAb} changeValue={setAnzeigenAb}/>
                                </div>
                            </div>
                        }
                        {(eventOperatorId === null || eventOperatorId === undefined) &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowMaxAverageNotice(false)} show={showMaxAverageNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>Als Avg. wird die 60 Tage Average des Spielers genommen.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 200}}>Average Obergrenze:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={(e) => setShowMaxAverageNotice(!showMaxAverageNotice)}/>
                                </div>
                                <div className="col">
                                    <InputNumber value={averageObergrenze} disabled={istTurnierGestartet() || istTurnierAbgeschlossen()}
                                                 style={{width: 200}}
                                                 onChange={(e) => setAverageObergrenze(e.value)}
                                                 mode="decimal" locale="de-DE" minFractionDigits={2}/>
                                </div>
                            </div>
                            }
                        {(eventOperatorId === null || eventOperatorId === undefined) &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowTeilnahmebedingungenNotice(false)} show={showTeilnahmebedingungenNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>Sofern du Teilnahmebedingungen hinterlegt hast, muss der
                                            Ligateilnehmer diese Teilnahmebedingungen bei Ligateilnahme bestätigen.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 200}}>Abfrage Teilnahmebedingungen:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={(e) => setShowTeilnahmebedingungenNotice(!showTeilnahmebedingungenNotice)}/>
                                </div>
                                <div className="col-fixed" style={{width: "100px"}}>
                                    <ToggleButton  className={ istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                                   checked={teilnahmebedingungen}
                                                   onChange={(e) => setTeilnahmebedingungen(!teilnahmebedingungen)}
                                                   onLabel="Ja" offLabel="Nein"/>
                                </div>
                            </div>
                        }
                        <div className="grid">
                            <div className="col-fixed" style={{width: 200}}>Hin. u. Rückspiel:</div>
                            <div className="col-fixed" style={{width: "100px"}}>
                                <ToggleButton
                                    className={ istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                    checked={hinUndRueckspiel}
                                              onChange={(e) => setHinUndRueckspiel(!hinUndRueckspiel)}
                                              onLabel="Ja" offLabel="Nein"/>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 200}}>Doppelliga:</div>
                            <div className="col-fixed" style={{width: "200px"}}>
                                <ToggleButton
                                    className={ istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                    onLabel="Ja" offLabel="Nein"
                                              checked={doppelturnier}
                                              onChange={(e) => setDoppelturnier(!doppelturnier)}/>
                            </div>
                        </div>
                        {(eventOperatorId === null || eventOperatorId === undefined) &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowUrkundendruckNotice(false)} show={showUrkundendruckNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>Für abgeschlossene Ligen können Urkunden generiert
                                            werden, hierfür musst du einen 'Urkundenrohlung' mit dem Admin vereinbaren und los
                                            gehts.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 200}}>Urkundengenerierung:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={(e) => setShowUrkundendruckNotice(!showUrkundendruckNotice)}/></div>
                                <div className="col-fixed" style={{width: "200px"}}>
                                    <ToggleButton
                                        className={istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                        onLabel="Ja" offLabel="Nein"
                                        checked={urkundendruck}
                                        onChange={(e) => setUrkundendruck(!urkundendruck)}/>
                                </div>
                            </div>
                        }
                        {(eventOperatorId === null || eventOperatorId === undefined) && <>
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowRanglisteNotice(false)} show={showRanglisteNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>Sofern du die Liga als Rangliste markierst, wird diese
                                            bei der Ranglistenberechnung berücktsichtigt.
                                        </div>
                                        <div style={{marginBottom: 5}}>Achtung!!! Du musst Ranglistenpunkte im System
                                            definieren. Bei Fragen wende dich an den Admin.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 200}}>Rangliste:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={(e) => setShowRanglisteNotice(!showRanglisteNotice)}/></div>
                                <div className="col-fixed" style={{width: "200px"}}>
                                    <ToggleButton onLabel="Ja" offLabel="Nein"
                                                  checked={rangliste}
                                                  onChange={(e) => setRangliste(!rangliste)}/>
                                </div>
                            </div>
                            {rangliste &&
                                <div className="grid">
                                    <div className="col-fixed" style={{width: 200}}>Markers:</div>
                                    <div className="col-fixed" style={{width: "200px"}}>
                                        <RanglisteMarkerComponent onValueChange={(markers) => setRanglistemarkers(markers)}
                                                                  selectedMarkers={ranglistemarkers}/>
                                    </div>
                                </div>
                            }
                        </>
                        }
                        {(eventOperatorId === null || eventOperatorId === undefined) &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowOnlineNotice(false)} show={showOnlineNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>
                                            Sofern du die Liga als online markierst, wird dieses online über Darthelfer gespielt. In diesem Fall müssen sich DH Benutzer an der Liga anmelden und
                                            spielen dieses
                                            Turnier gegen einen Onlinegegner.
                                        </div>
                                        <div style={{marginBottom: 5}}>
                                            Offline ist für Ligen in deinem Vereinsheim oder Zuhause. In diesem Fall kannst du die Ergebnisse von Hand in DH eintragen. Über den DH-Client kann gespielt
                                            werden, in diesem Fall werden die Ergebnisse automatisch eingetragen.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 200}}>Online:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={(e) => setShowOnlineNotice(!showOnlineNotice)}/>
                                </div>
                                <div className="col-fixed" style={{width: "200px"}}>
                                    <ToggleButton onLabel="Ja" offLabel="Nein"
                                                  checked={online}
                                                  className={ eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                                  onChange={(e) => setOnline(!online)}/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </TabPanel>
            {(eventOperatorId === null || eventOperatorId === undefined) &&
                <TabPanel header="Admins">
                    <TurnierAdminTabPanel changeAdmins={(admins) => setAdmins(admins)} onError={(message) => setErrorMessage(message)} admins={admins}/>
                </TabPanel>
            }
            {(eventOperatorId === null || eventOperatorId === undefined) &&
                <TabPanel header="Sponsoren">
                    <TurnierSponsorenTabPanel onError={(message) => setErrorMessage(message)} sponsoren={sponsoren}
                                              changeSponsoren={(sponsoren) => setSponsoren(sponsoren)}/>
                </TabPanel>
            }
            <TabPanel header="Veranstalter" disabled={eventOperatorId === null || eventOperatorId === undefined}>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-6">
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowPublishTournamentNotice(false)} show={showPublishTournamentNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>Datenschutz und Anonymität ist uns sehr wichtig. Auf der öffentlichen
                                        Turnierseite von Darthelfer kannst du dein Turnier anzeigen lassen. Bitte wähle dazu hier 'Ja'.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Liga veröffentlichen: *
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={(e) => setShowPublishTournamentNotice(!showPublishTournamentNotice)}/>
                            </div>
                            <div className="col">
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={publishTournament}
                                              className={ eventOperatorId === null || eventOperatorId === undefined || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={(e) => setPublishTournament(!publishTournament)}/>
                            </div>
                        </div>
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowOnlineNotice(false)} show={showOnlineNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>
                                        Sofern du das Turnier als online markierst, wird dieses online über Darthelfer gespielt. In diesem Fall müssen sich DH Benutzer am Turnier anmelden und spielen
                                        dieses Turnier gegen einen Onlinegegner.
                                    </div>
                                    <div style={{marginBottom: 5}}>
                                        Offline ist für Turniere in deinem Vereinsheim oder Zuhausen. In diesem Fall kannst du die Ergebnisse von Hand in DH eintragen. Über den DH-Client kann gespielt
                                        werden, in diesem Fall werden die Ergebnisse automatisch eingetragen.
                                    </div>
                                    <div>
                                        Bitte beachte die Option Scoring mit Client. Sofern du über den darthelfer-scorer spielen willst, muss diese Option gesetzt werden. Wenn nicht, musst du das
                                        Ergebnis von Hand im Turnierbaum oder im Gruppenturnier eintragen.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Online:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={(e) => setShowOnlineNotice(!showOnlineNotice)}/>
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={online}
                                              className={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={(e) => setOnline(!online)}/>
                            </div>
                        </div>
                        {!online &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowScoringNotice(false)} show={showScoringNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>
                                            Sofern du dein Turnier mit Darthelfer scoren willst, wähle hier 'Ja'. Den
                                            darthelfer-scorer findest du unter https://scorer.darthelfer.de
                                        </div>
                                        <div style={{marginBottom: 5}}>
                                            Hilfen dazu findest du unter support.darthelfer.de oder auf der Startseite von
                                            darthelfer.de
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 220}}>Scoring mit Client:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={(e) => setShowScoringNotice(!showScoringNotice)}/>
                                </div>
                                <div className="col-fixed" style={{width: 220}}>
                                    <ToggleButton onLabel="Ja" offLabel="Nein"
                                                  checked={scoringWithDarthelfer}
                                                  className={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                                  onChange={(e) => setScoringWithDarthelfer(!scoringWithDarthelfer)}/>
                                </div>
                            </div>
                        }
                        {!online && scoringWithDarthelfer &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowScoringTypeNotice(false)} show={showScoringTypeNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>
                                            Nur relevant sobald der Scorer genutzt wird!
                                        </div>
                                        <div style={{marginBottom: 5}}>
                                            Bei E-Dart können im Scorer die Legs erfasst werden und am Ende das Spiel übermittelt werden.
                                        </div>
                                        <div style={{marginBottom: 5}}>
                                            Bei Steeldarts kann das gesamte Spiel im Scorer gespielt werden.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 220}}>Dart-Typ:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={(e) => setShowScoringTypeNotice(!showScoringTypeNotice)}/>
                                </div>
                                <div className="col-fixed" style={{width: 220}}>
                                <SelectButton optionLabel="name" value={dartType} options={darttypes} onChange={(e) => setDartType(e.value)}></SelectButton>
                                </div>
                            </div>
                        }
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowAnmeldungPublicNotice(false)} show={showAnmeldungPublicNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>
                                        Wähle 'Ja', wenn die Anmeldung zu deinem Turnier durch den Teilnehmer möglich ist.
                                    </div>
                                    <div style={{marginBottom: 5}}>
                                        Wähle 'Nein', wenn du die Teilnehmer selbst eintragen möchtest. In diesem Fall ist eine Anmeldung über die darthelfer-Turnierübersicht nicht möglich. Nur du und
                                        deine Admins könnt Teilnehmer hinzufügen.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Anmeldung möglich:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={(e) => setShowAnmeldungPublicNotice(!showAnmeldungPublicNotice)}/>
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={registrationAllowed}
                                              className={ eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={(e) => setRegistrationAllowed(!registrationAllowed)}/>
                            </div>
                        </div>
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowAnmeldungUnbestaetigtNotice(false)} show={showAnmeldungUnbestaetigtNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>
                                        Wähle 'Ja', wenn du die Anmeldung vom System automatisch löschen lassen willst, sofern der Teilnehmer die Anmeldung in der Mail nicht bestätigt (Aktuell nach 24
                                        Std.). Die soll SPAM-Anmeldungen verhinden.
                                    </div>
                                    <div style={{marginBottom: 5}}>
                                        Wähle 'Nein', Anmeldung wird nicht automatisch vom System gelöscht.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Unbestätigte Anmeldung löschen:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={(e) => setShowAnmeldungUnbestaetigtNotice(!showAnmeldungUnbestaetigtNotice)}/>
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={deleteUnconfirmedRegistrations}
                                              className={ eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={(e) => setDeleteUnconfirmedRegistrations(!deleteUnconfirmedRegistrations)}/>
                            </div>
                        </div>
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowTeilnahmePerMailNotice(false)} show={showTeilnahmePerMailNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>
                                        Wähle 'Ja', wenn Teilnehmer Ihre Teilnahme bestätigen müssen. Die Teilnehmer erhalten nach der Anmeldung eine Mail und müssen innerhalb eines kurzen Zeitraums
                                        die Teilnahme bestätigen.
                                    </div>
                                    <div style={{marginBottom: 5}}>
                                        Wähle 'Nein', wenn die Teilnahme nicht per Mail bestätigt werden muss. In diesem Fall hast du das Risiko, dass ein Roboter oder eine Person die Anmeldung
                                        durchführt und du evtl. Teilnehmer gemeldet hast die nicht die Absicht haben mitzuspielen.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Teilnahme per Mail bestätigt:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={(e) => setShowTeilnahmePerMailNotice(!showTeilnahmePerMailNotice)}/>
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={participantsMailConfirmation}
                                              className={ eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={(e) => setParticipantsMailConfirmation(!participantsMailConfirmation)}/>
                            </div>
                        </div>
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowBezahlungNotice(false)} show={showBezahlungNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>
                                        Sofern für die Turnierteilnahme eine Gebühr notwendig ist, stelle hier auf 'Ja'. Im Mailtext kannst du dann deine Infos dafür angeben.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Bezahlung:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={(e) => setShowBezahlungNotice(!showBezahlungNotice)}/>
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={bezahlung}
                                              className={ eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={(e) => setBezahlung(!bezahlung)}/>
                            </div>
                        </div>
                        {bezahlung &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowBezahlungBetragNotice(false)} show={showBezahlungBetragNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>
                                            Bezahlbetrag. Dieser muss für die Turnierteilnahme entrichtet werden. Wir fügen diese
                                            Info einer entsprechenden Bestätigungsmail hinzu.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 220}}>Bezahlbetrag:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={(e) => setShowBezahlungBetragNotice(!showBezahlungBetragNotice)}/>
                                </div>
                                <div className="col-fixed" style={{width: 220}}>
                                    <div className="col" style={{width: "100%"}}>
                                        <InputText value={bezahlungbetrag} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                                   onChange={(e) => setBezahlungbetrag(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        }
                        {participantsMailConfirmation &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowMailNotice(false)} show={showMailNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>
                                            Der gesamte Text wird in der Bestätigungsmail eingefügt.
                                        </div>
                                        <div style={{marginBottom: 5}}>
                                            Füge in den Text eventuelle Bezahlinformationen wie Bankdaten oder PayPal Infos ein. Sofern der Betrag gefühlt ist, fügen wir dies Information automatisch
                                            hinzu.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 220}}>Mailtext:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={(e) => setShowMailNotice(!showMailNotice)}/>
                                </div>
                                <div className="col-fixed" style={{width: 220}}>
                                    <div className="col" style={{width: "100%"}}>
                                        <InputTextarea rows={5} cols={50} maxLength={5000} value={mailtext}
                                                       disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                                       onChange={(e) => setMailtext(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-12 md:col-12 lg:col-6">
                        <Button label="Veranstalter- und Ausrichtungsortdaten aus Standard vorbelegen?" onClick={() => eventOperatorDatenVorbelegen()}/>
                        <h3>Veranstalterdaten</h3>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Telefon:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortTelefon} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortTelefon(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Email:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortEmail} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortEmail(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Socialmedia-Link:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={socialmedia} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setSocialmedia(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Homepage-Link:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={homepage} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setHomepage(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <h3>Daten zum Austragungsort</h3>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Bezeichnung<br/>(z.B Sportheim Hummelshausen):
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortBezeichnung} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortBezeichnung(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Strasse:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortStrasse} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortStrasse(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>PLZ:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortPLZ} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortPLZ(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Ort:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortOrt} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortOrt(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Aktionen" disabled={id === undefined}>
                <LigaAktionenComponent showAsSidebar={false} proTournament={liga} onInfo={(message) => setInfoMessage(message)}
                                                               onClose={() => ""}
                                                               callBackLoadNewTournament={(newId) => {
                                                                ladeLiga(newId);
                                                                callBackRefresh();
                                                            }}
                                                               callBackOnDelete={() => {
                                                                callBackRefresh();
                                                                callBackOnClose();
                                                            }}
                                                               callBackRefresh={() => {
                                                                ladeLiga(id);
                                                                callBackRefresh();
                                                            }}
                                                               callBackCloseDialog={() => {
                                                                callBackRefresh();
                                                                callBackOnClose();
                                                            }}
                                                               onClearMessages={() => setErrorMessage(undefined)} onError={(message) => setErrorMessage(message)}/>
            </TabPanel>
            {id !== undefined &&
                <TabPanel header="QRCodes">
                    <TurnierQRCode tournamentId={id} eventOperatorId={eventOperatorId} onlineTournament={online} tournamentSystem={TOURNAMENTSYSTEMS.LIGA.id} bezeichnung={bezeichnung}/>
                </TabPanel>
            }
        </TabView>
        <div style={{marginTop:20, fontSize:10}}>TurnierId: {id}</div>
        {showPublishTournamentInfo && <InfoDialogEventNichtVeroeffentlichen onClose={() => setShowPublishTournamentInfo(false)}/>}
    </>}/>
}

LigaVerwaltenDialog.propTypes = {
    callBackOnClose: PropTypes.func.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
    propsEventOperatorId: PropTypes.string,
    ligaId: PropTypes.string,
};

export default LigaVerwaltenDialog
