import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import TournamentTable from "../tournament/TournamentTable";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import TurnierVerwaltenDialog from "../tournament/TurnierVerwaltenDialog";
import LigaVerwaltenDialog from "../tournament/LigaVerwaltenDialog";
import TournamentCopySidebar from "../public/tournaments/TournamentCopySidebar";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import LigaspieltagVerwaltenDialog from "../tournament/LigaspieltagVerwaltenDialog";
import {Button} from "primereact/button";
import LigaAktionenComponent from "../tournament/tabs/LigaAktionenComponent";

function VeranstalterTournamentOverview({eventOperatorId, userId, counter}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [showNewEvents, setShowNewEvent] = useState(true);
    let [showStartedEvents, setShowStartedEvent] = useState(true);
    let [showClosedEvents, setShowClosedEvent] = useState(false);

    let [showLigaspieltagDialog, setShowLigaspieltagDialog] = useState(false);
    let [showLigaDialog, setShowLigaDialog] = useState(false);
    let [showKODKODialog, setShowKODKODialog] = useState(false);
    let [showTurnierKopierenFrage, setShowTurnierKopierenFrage] = useState(false);
    let [showAktionen, setShowAktionen] = useState(false);
    let [selectedTournament, setSelectedTournament] = useState(undefined);

    let [tournaments, setTournaments] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, [eventOperatorId, counter]);

    const ladeDaten = () => {
        if( eventOperatorId === undefined) {
            setTournaments([]);
            return;
        }
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/byEventOperator/" + eventOperatorId,
            json => {
                setTournaments(json.all);
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
            },
            error => {
                setErrorMessage(error.message);
            });
    }

    const callBackTurnierBearbeiten = (tournament) => {
        setSelectedTournament(tournament);
        if( tournament.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id) {
            setShowLigaspieltagDialog(true);
        } else if( tournament.turniersystem === TOURNAMENTSYSTEMS.LIGA.id) {
            setShowLigaDialog(true);
        } else if (tournament.turniersystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id || tournament.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id || tournament.turniersystem === TOURNAMENTSYSTEMS.EINFACHERKO.id ||
            tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id || tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id
            || tournament.turniersystem === TOURNAMENTSYSTEMS.KRATZER.id || tournament.turniersystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id
            || tournament.turniersystem === TOURNAMENTSYSTEMS.ANY_GAMES.id) {
            setShowKODKODialog(true);
        } else {
            setErrorMessage("Fehlerhafte Turnierform. Admin verständigen.");
        }
    }

    const callBackTurnierKopieren = (tournament) => {
        setSelectedTournament(tournament);
        setShowTurnierKopierenFrage(true);
    }

    const callBackShowAktionen = (tournament) => {
        setSelectedTournament(tournament);
        setShowAktionen(true);
    }

    const getFiltertTournaments = () => {
        let newTournaments = [];
        for (const tournament of tournaments) {
            if( tournament.tournamentstatus === "gestartet" && showStartedEvents) {
                newTournaments.push(tournament);
            } else if( tournament.tournamentstatus === "abgeschlossen" && showClosedEvents) {
                newTournaments.push(tournament);
            } else if( tournament.tournamentstatus === "neu" && showNewEvents) {
                newTournaments.push(tournament);
            }
        }
        return newTournaments;
    }

    return <div className="col-12 md:col-12 lg:col-10" style={{padding: 10}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid #0497ae"}}>
            <div className="grid">
                <div className="col"><h3>Eventübersicht</h3></div>
            </div>
            <div style={{marginBottom:10}}>Anzahl Events: {tournaments.length}</div>
            <div style={{marginBottom:10}}>
                <Button label="Neu" className={showNewEvents ? "p-button-primary" : "p-button-secondary"} onClick={() => setShowNewEvent(!showNewEvents)} />
                <Button label="Gestartet" className={showStartedEvents ? "p-button-primary" : "p-button-secondary"} onClick={() => setShowStartedEvent(!showStartedEvents)} />
                <Button label="Abgeschlossen" className={showClosedEvents ? "p-button-primary" : "p-button-secondary"} onClick={() => setShowClosedEvent(!showClosedEvents)} />
            </div>
            <div>
                <TournamentTable tournaments={getFiltertTournaments()} showButtons={true} onTournamentCopy={callBackTurnierKopieren}
                                 onTournamentEdit={callBackTurnierBearbeiten} onShowAktionen={callBackShowAktionen}/>
            </div>
        </div>
        {showLigaspieltagDialog && <LigaspieltagVerwaltenDialog tournamentId={selectedTournament !== undefined ? selectedTournament.id : null}
                                                    callBackRefresh={ladeDaten}
                                                    callBackOnDelete={() => {
                                                        ladeDaten();
                                                        setShowLigaspieltagDialog(false);
                                                        setSelectedTournament(false);
                                                    }}
                                                    callBackOnClose={() => {
                                                        setShowLigaspieltagDialog(false);
                                                        setSelectedTournament(false);
                                                    }}/>
        }
        {showKODKODialog && <TurnierVerwaltenDialog tournamentId={selectedTournament !== undefined ? selectedTournament.id : null}
                                                    callBackRefresh={ladeDaten}
                                                    callBackOnDelete={() => {
                                                        ladeDaten();
                                                        setShowKODKODialog(false);
                                                        setShowLigaDialog(false);
                                                        setSelectedTournament(false);
                                                    }}
                                                    callBackOnClose={() => {
                                                        setShowKODKODialog(false);
                                                        setShowLigaDialog(false);
                                                        setSelectedTournament(false);
                                                    }}/>
        }
        {showLigaDialog && <LigaVerwaltenDialog ligaId={selectedTournament !== undefined ? selectedTournament.id : null}
                                                           callBackRefresh={ladeDaten}
                                                           callBackOnClose={() => {
                                                               setShowLigaDialog(false);
                                                               setSelectedTournament(undefined);
                                                           }}/>
        }
        {showAktionen && <LigaAktionenComponent showAsSidebar={true} onError={(message) => setErrorMessage(message)}
                                                           onInfo={(message) => setInfoMessage(message)}
                                                           onClearMessages={() => setErrorMessage(undefined)}
                                                           callBackRefresh={() => ladeDaten()}
                                                           callBackCloseDialog={() => setShowAktionen(false)}
                                                           tournamentId={selectedTournament.id}
                                                            userId={userId}
                                                           onClose={() => setShowAktionen(false)}
                                                           callBackOnDelete={() => {
                                                               ladeDaten();
                                                               setShowAktionen(false);
                                                           }}
                                                           callBackLoadNewTournament={() => ladeDaten()}/>
        }
        {showTurnierKopierenFrage &&
            <TournamentCopySidebar
                tournamentId={selectedTournament.id}
                turniersystem={selectedTournament.turniersystem}
                propMannschaftHeim={selectedTournament.mannschaftHeim}
                propBezeichnung={selectedTournament.bezeichnung}
                propBezeichnungKurz={selectedTournament.bezeichnungShort}
                onCopy={() => ladeDaten()}
                onClose={() => {
                    setShowTurnierKopierenFrage(false);
                    setSelectedTournament(undefined);
                    }}
            />
        }

    </div>
}

export default VeranstalterTournamentOverview;
